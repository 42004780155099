<template>
  <div class="newsList">
    <Swiper></Swiper>
   学习力
    <el-tabs :tab-position="tabPosition" style="height: 4.9rem">
      <el-tab-pane label="小新快讯">
        <div class="littleMess" @click="goDetail(i)" v-for="(item, i) in messList" :key="i">
          <div class="img"></div>
          <div class="right">
            <p class="mess">{{ item.mess }}</p>
            <p>2021-4-1</p>
          </div>
        </div>
        <div class="page">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="100"
             @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      v-model:currentPage="currentPage1"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="行业动态">
      <div class="news"  @click="goDetail(i)"  v-for="(item, i) in messList" :key="i">
          <div class="img"></div>
          <div class="right">
            <p class="mess">ccc{{ item.mess }}</p>
            <p>2021-4-1</p>
          </div>
        </div>
        <div class="page">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="100"
               @size-change="handleSizeChange2"
      @current-change="handleCurrentChange2"
      v-model:currentPage="currentPage2"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- <SubNav></SubNav> -->
    <!-- <div class="news">
      <MiddleBox></MiddleBox>
      <div class="right">
        <long-box></long-box>
      </div>
    </div> -->
    <!-- 小新快讯 和 行业动态动态标签 -->
    <!-- <div class="list">
      <p @click="goDetail(111)">去详情!!!!!!!!!!!</p>
    </div> -->
    <div class="message">
      <message></message>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import LongBox from "./modules/longBoxa.vue";
import MiddleBox from "./modules/middleBox.vue";
import Message from "@/components/message/Message.vue";

export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    LongBox,
    MiddleBox,
    Message,
  },
  data() {
    return {
      tabPosition: "left",
      currentPage1:1,
      currentPage2:1,
      messList: [
        {
          id: 1,
          mess:
            "高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 2,
          mess:
            "2高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 3,
          mess:
            "3高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 3,
          mess:
            "3高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
      ],
    };
  },
  methods: {
    goDetail(id: any) {
      // console.log(id);
      this.$router.push({ name: "newsDetail", params: { id: id } });
    },
      handleSizeChange(val:any) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val:any) {
        console.log(`当前页: ${val}`);
      },
       handleSizeChange2(val:any) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange2(val:any) {
        console.log(`当前页: ${val}`);
      }
  },
});
</script>
<style lang="scss" >
.newsList {
.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color:#00a5a8; //修改后的背景图颜色
    color: #fff;
  }
  width: 1200px;
  background-color: #fff;
  // .el-tabs__nav-wrap::after {
  //   height: 1px;
  // }
  .el-tabs__item {
    height: 50px;
    font-size: 28px;
    font-weight: bold;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333;
  }
  .el-tabs__item:hover {
    color: #00a5a8;
  }
  .el-tabs__item.is-active {
    color: #00a5a8;
  }
  .el-tabs__active-bar {
    background: #00a5a8;

    /* width: 30px; */
  }

  .message {
    width: 1200px;
    // background-color: skyblue;
    height: 100%;
    margin-bottom: 20px;
    margin-top: 56px;
  }
  .littleMess {
    width: 770px;
    height: 154px;
    margin-left: 120px;
    margin-bottom: 51px;
    .img {
      width: 250px;
      height: 154px;
      float: left;
      background-color: #777;
    }
    .right {
      float: left;
      width: 380px;
      height: 100px;
      margin-left: 110px;
      margin-top: 14px;
      .mess {
        border-bottom: 1px dashed #00a5a8;
        padding-bottom: 15px;
        line-height: 20px;
        margin-bottom: 16px;
      }
    }
  }
 .news {
    width: 770px;
    height: 154px;
    margin-left: 120px;
    margin-bottom: 51px;
    .img {
      width: 250px;
      height: 154px;
      float: left;
      background-color: #777;
    }
    .right {
      float: left;
      width: 380px;
      height: 100px;
      margin-left: 110px;
      margin-top: 14px;
      .mess {
        border-bottom: 1px dashed #00a5a8;
        padding-bottom: 15px;
        line-height: 20px;
        margin-bottom: 16px;
      }
    }
  }
  .page {
    float: right;
  }
}
</style>
